<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <person-form v-model="model" @cancel="onCancel" @save="onSave" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import personForm from './form.vue'
import personApi from '@/services/api/person'

export default {
  components: {
    appToolbar,
    personForm
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    crumbs() {
      return [
        { text: this.$t('people'), to: { name: 'people-index' } },
        { text: this.$t('create'), disabled: true }
      ]
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage', 'addError']),
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model) {
      try {
        const response = await personApi.post(model)
        this.addMessage({
          message: `${this.$t('person')}
          ${model.name}
          ${this.$t('hasBeenCreated')}`
        })
        this.$router.push({
          name: 'person-detail',
          params: { personId: response.data.id }
        })
      } catch (e) {
        this.addError(e)
      }
    }
  }
}
</script>
